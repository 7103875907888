.App {
  text-align: center;
}

.App-header {
  animation-name: backgroundColorPalette;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  min-height: 100vh;
  display: flex;
}

@keyframes backgroundColorPalette {
  0% {
    background: #E31D1A;
  }
  20% {
    background: #638B18;
  }
  40% {
    background: #F6A704;
  }
  60% {
    background: #1F3E6D;
  }
  80% {
    background: #9778D3;
  }
  100% {
    background: #E31D1A;
  }
}
